import styled from "styled-components";
import React from "react";

export const SolInput = styled.input`
    padding: 6px 0px 6px 12px;
    height: 36px;
    width: 100%;
    /* Global/09. White */
    background-color: #FFFFFF;
    /* Components/Forms/Input border */
    border: 1px solid #BCBCCD;
    box-sizing: border-box;
    border-radius: 4px;
    
    
    
    :hover, :focus, :active {
      border: 1px solid #F18D00;
      box-shadow: 0px 0px 0px 3.2px rgba(241, 141, 0, 0.1);
      outline: none;
    }  
`

export const ActionMenu=()=> (<span className="material-icons" style={{color: '#F18D00'}}>more_vert</span>)


export const SolTextArea = styled.textarea`
    padding: 6px 0px 6px 12px;
    width: 100%;
    /* Global/09. White */
    background-color: #FFFFFF;
    /* Components/Forms/Input border */
    border: 1px solid #BCBCCD;
    box-sizing: border-box;
    border-radius: 4px;
    
    
    :hover, :focus, :active {
      border: 1px solid #F18D00;
      box-shadow: 0px 0px 0px 3.2px rgba(241, 141, 0, 0.1);
      outline: none;
    }  
`